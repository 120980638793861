import React, {useRef} from 'react';
import './App.css';

/*
// First draft - just getting something to show
const items = ["C3P0", "Chewbacca", "Ewok", "HanSolo", "Jabba", "Leia", "Luke", "R2D2", "Vader", "Yoda", "fullrobot"];
function App() {
  const listItems = items.map(it =>
      <figure>
          <img
              key={it}
              src={'img/'+it+'.svg'}
              alt={it.toString()}
          />
      </figure>);
  return (
    <section className="soundboard">
      {listItems}
    </section>
  );
}
*/

/*
// Second draft - adding data object and sounds
const data = {
    items: [
        { name: "C3P0 Human-cyborg Relations", img: "C3P0.svg", sound: "C3P0.mp3" },
        { name: "last of the Wookies: Chewbacca", img: "Chewbacca.svg", sound: "Chewbacca.mp3" },
        { name: "An Everyday Ewok", img: "Ewok.svg", sound: "Ewok.mp3" },
        { name: "Han Solo, Smuggler, Romantic, Rebel General", img: "HanSolo.svg", sound: "HanSolo.mp3" },
        { name: "Jabba da Hut", img: "Jabba.svg", sound: "Jabba.mp3" },
        { name: "Princess Leia", img: "Leia.svg", sound: "Leia.mp3" },
        { name: "Jedi Luke", img: "Luke.svg", sound: "Luke.mp3" },
        { name: "R2D2 Swiss army droid", img: "R2D2.svg", sound: "R2D2.mp3" },
        { name: "Darth Vader, Luke's daddy", img: "Vader.svg", sound: "Vader.mp3" },
        { name: "Jedi Master Yoda", img: "Yoda.svg", sound: "Yoda.mp3" },
        { name: "Houston You've made another mess", img: "fullrobot.svg", sound: "Error.mp3" }
        ]
}
function playSound(soundFile: string, audioRef: React.MutableRefObject<HTMLAudioElement>) {
    const audio = new Audio('sounds/'+soundFile);
    audio.addEventListener("canplaythrough", (e)=> {
            audioRef.current.pause();
            audioRef.current = audio;
            audio.play().catch(e=>console.error(e));
        }
    );
    /!*
    // Off course, this is a nogo in React, we need a Hook
    // https://dmitripavlutin.com/react-useref-guide/ ;-)
    audio.addEventListener("playing",
        (e) => {
            console.log(e.target);
            console.log(document.querySelectorAll("audio"));
            Array.from(document.getElementsByTagName("audio")).forEach(el => {
                console.log(el);
                if (el !== e.target) el.pause();
                console.log(el);
            })
        });
      *!/
}
function App() {
    let audioRef = useRef(new Audio());
    return (
        <section className="soundboard">
            <h1>Children need to learn these sounds too!</h1>
            {data.items.map((it, n) =>
            <figure key={n}>
                <img
                    src={'img/'+it.img}
                    alt={it.img.substring(0, it.img.lastIndexOf('.'))}
                    title={it.name}
                    onClick={() => playSound(it.sound, audioRef)}
                />
            </figure>
            )}
        </section>
    );
}
*/

// third Draft - multiple components
const data = {
    items: [
        { name: "C3P0 Human-cyborg Relations", img: "C3P0.svg", sound: "C3P0.mp3" },
        { name: "last of the Wookies: Chewbacca", img: "Chewbacca.svg", sound: "Chewbacca.mp3" },
        { name: "An Everyday Ewok", img: "Ewok.svg", sound: "Ewok.mp3" },
        { name: "Han Solo, Smuggler, Romantic, Rebel General", img: "HanSolo.svg", sound: "HanSolo.mp3" },
        { name: "Jabba da Hut", img: "Jabba.svg", sound: "Jabba.mp3" },
        { name: "Princess Leia", img: "Leia.svg", sound: "Leia.mp3" },
        { name: "Jedi Luke", img: "Luke.svg", sound: "Luke.mp3" },
        { name: "R2D2 Swiss army droid", img: "R2D2.svg", sound: "R2D2.mp3" },
        { name: "Darth Vader, Luke's daddy", img: "Vader.svg", sound: "Vader.mp3" },
        { name: "Jedi Master Yoda", img: "Yoda.svg", sound: "Yoda.mp3" },
        { name: "Houston You've made another mess", img: "fullrobot.svg", sound: "Error.mp3" }
    ]
}
function playSound(soundFile: string, audioRef: React.MutableRefObject<HTMLAudioElement>) {
    const audio = new Audio('sounds/'+soundFile);
    audio.addEventListener("canplaythrough", (e)=> {
            audioRef.current.pause();
            audioRef.current = audio;
            audio.play().catch(e=>console.error(e));
        }
    );
    audio.addEventListener("playing", (e)=> {
            document.querySelector('img[data-sound="' + soundFile + '"]')?.parentElement?.classList.toggle('playing');
        }
    );
    audio.addEventListener("ended", (e)=> {
            document.querySelector('img[data-sound="' + soundFile + '"]')?.parentElement?.classList.toggle('playing');
        }
    );
}
function SoundboardItem({ it, audioRef }: any) { //https://bobbyhadz.com/blog/typescript-binding-element-implicitly-has-an-any-type#:~:text=The%20error%20%22Binding%20element%20implicitly,in%20functions%20and%20class%20methods.
    return (
        <figure>
            <img
                src={'img/'+it.img}
                alt={it.img.substring(0, it.img.lastIndexOf('.'))}
                title={it.name}
                data-sound={it.sound}
                onClick={() => playSound(it.sound, audioRef)}
            />
        </figure>
    );
}
function Soundboard({soundboard}:any) {
    let audioRef = useRef(new Audio());
    return (
        <section className="soundboard">
            <h1>Children need to learn these sounds too!</h1>
            {soundboard.items.map((it:any, n:any) =>
                <SoundboardItem key={n} it={it} audioRef={audioRef}/>
            )}
        </section>
    );
}
function App() {
    return (
        <>
            <Soundboard soundboard={data} />
        </>
    );
}
export default App;
